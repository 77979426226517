import React from 'react'
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import "./CompletedList.css"
import { useState, useEffect } from 'react';
import { db } from '../Firebase';
import "./CompletedList.css"
import { collection, doc, getDocs } from 'firebase/firestore';
const PendingList = () => {
  const [numPendingSessions, setNumPendingSessions] = useState(0);
  const [completedSessions, setCompletedSessions] = useState([]);

  useEffect(() => {
      const fetchNumPendingSessions = async () => {
        const userEmail = localStorage.getItem('email');
  
        if (userEmail) {
          const userSubcollectionRef = collection(db, 'SpeakerUser', userEmail, 'NewSession');
          const querySnapshot = await getDocs(userSubcollectionRef);
  
          const currentDate = new Date();
          const formattedCurrentDate = currentDate.toISOString().slice(0, 10);
  
          const completedSessionsData = [];
          querySnapshot.forEach((doc) => {
            const sessionData = doc.data();
            const sessionDate = sessionData.dateAndTiming.toDate().toISOString().slice(0, 10);
  
            if (sessionDate > formattedCurrentDate) {
              completedSessionsData.push({
                title: sessionData.sessionHeading,
                date: sessionDate,
                description: sessionData.description,
                ceu: ''
              });
            }
          });
  
     setCompletedSessions(completedSessionsData);
          setNumPendingSessions(completedSessionsData.length);
        }
      };    fetchNumPendingSessions();
  }, []);
  return (
    <div className='completedListsContainer'>
    <DataTable tableStyle={{ minWidth: '50rem', borderRadius: '5px' }} value={completedSessions}>
      <Column field='title' header='Title'></Column>
      <Column field='description' header='Description'></Column>
      <Column field='date' header='Date'></Column>
      <Column field='ceu' header='CEU'></Column>
    </DataTable>
  </div>
  )
}

export default PendingList