import React from 'react'
import { Link, Outlet } from 'react-router-dom';
import "primeflex/primeflex.css";
import './Homepage.css'
import './CreateSession.css'
import { FcPlus } from "react-icons/fc";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { useState,useEffect } from 'react';
import { collection, doc, getDocs } from 'firebase/firestore';
import { db } from '../Firebase';



const CreateSession = () => {
    const [numCompletedSessions, setNumCompletedSessions] = useState(0);
    const [numPendingSessions, setNumPendingSessions] = useState(0);

    useEffect(() => {
        const fetchNumCompletedSessions = async () => {
          const userEmail = localStorage.getItem('email');
    
          if (userEmail) {
            const userSubcollectionRef = collection(db, 'SpeakerUser', userEmail, 'NewSession');
            const querySnapshot = await getDocs(userSubcollectionRef);
    
            const currentDate = new Date();
            const formattedCurrentDate = currentDate.toISOString().slice(0, 10);
    
            let count = 0;
            querySnapshot.forEach((doc) => {
              const sessionData = doc.data();
              const sessionDate = sessionData.dateAndTiming.toDate().toISOString().slice(0, 10);
    
              if (sessionDate < formattedCurrentDate) {
                count++;
              }
            });
    
            setNumCompletedSessions(count);
          }
        };    fetchNumCompletedSessions();
    }, []);

    useEffect(() => {
        const fetchNumPendingSessions = async () => {
          const userEmail = localStorage.getItem('email');
    
          if (userEmail) {
            const userSubcollectionRef = collection(db, 'SpeakerUser', userEmail, 'NewSession');
            const querySnapshot = await getDocs(userSubcollectionRef);
    
            const currentDate = new Date();
            const formattedCurrentDate = currentDate.toISOString().slice(0, 10);
    
            let count = 0;
            querySnapshot.forEach((doc) => {
              const sessionData = doc.data();
              const sessionDate = sessionData.dateAndTiming.toDate().toISOString().slice(0, 10);
    
              if (sessionDate > formattedCurrentDate) {
                count++;
              }
            });
    
            setNumPendingSessions(count);
          }
        };    fetchNumPendingSessions();
    }, []);
        
  return (
    <div className='createSessionPageWidth'>
    <div className=" gridSessionwidth">
    <Link to="/session/completedsessions" className='linkCompletedSessions'>
  <div className="col-6 md:col-6 lg:col-3" style={{ width: "100%" }}>
    <div className="surface-card shadow-2 p-3 border-round">
      <div className="flex justify-content-between mb-3">
        <div>
          <Link to="homepageyoursessions" className="block text-500 font-medium mb-3">Completed Sessions</Link>
          <div className="text-900 font-medium text-xl">{numCompletedSessions}</div>
        </div>
        <div className="flex align-items-center justify-content-center bg-blue-100 border-round"
          style={{ width: "2.5rem", height: "2.5rem" }}>
          <i className="pi pi-shopping-cart text-blue-500 text-xl" />
        </div>
      </div>
      <span className="text-green-500 font-medium">{numCompletedSessions} new </span>
      <span className="text-500">since last visit</span>
    </div>
  </div>
</Link>

    <Link to="/session/pendingsessions" className='linkCompletedSessions'>
    <div className="col-6 md:col-6 lg:col-3" style={{
      width: "100%"}}>
        <div className="surface-card shadow-2 p-3 border-round">
            <div className="flex justify-content-between mb-3">
                <div>
                    <Link to="livesessions" className="block text-500 font-medium mb-3">Upcoming Sessions</Link>
                    <div className="text-900 font-medium text-xl">{numPendingSessions}</div>
                </div>
                <div className="flex align-items-center justify-content-center bg-orange-100 border-round"
                style={{width:"2.5rem",height:"2.5rem",}}>
                    <i className="pi pi-map-marker text-orange-500 text-xl"/>
                </div>
            </div>
            <span className="text-green-500 font-medium">{numPendingSessions} new </span>
            <span className="text-500">since last visit</span>
        </div>
    </div>
    </Link>
   
</div>
<Link className='createNewSessionFormLink' to="/session/newsession">
<div className='createNewSessionDiv'>
<div className='createNewSessionButtonDiv'>
<FcPlus/>
<p className='createNewSessionLine'>Create New Session</p>
</div>
</div>
</Link>
<div>
<div className='createSessionOutlet'>
<Outlet/>
</div>
</div>

    </div>
  )
}

export default CreateSession