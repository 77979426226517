import "./App.css";
import "/node_modules/primeflex/primeflex.css";
import { HiOutlineHome, HiOutlinePlus ,HiOutlineVideoCamera} from "react-icons/hi";
import { GiTeacher } from "react-icons/gi";
import { Sidebar } from "primereact/sidebar";
import { useState } from "react";
import React from "react";
import { FaRegWindowRestore } from "react-icons/fa";
import { SlideMenu } from "primereact/slidemenu";
import { BiSupport, BiUser, BiLogOut } from "react-icons/bi";
import { BsFillFileEarmarkTextFill } from "react-icons/bs";
import { HiBars2 } from "react-icons/hi2";
import { AiOutlineDown } from "react-icons/ai";
import Signup from "./pages/Signup";
import {  GoogleAuthProvider, signInWithPopup,signOut } from "firebase/auth";
import { auth } from "../src/Firebase";
//theme
import "primereact/resources/themes/lara-light-indigo/theme.css";
import { AnimatePresence, motion } from "framer-motion";
//core
import "primereact/resources/primereact.min.css";

//icons
import "primeicons/primeicons.css";
import { Button } from "primereact/button";
import { NavLink, Outlet } from "react-router-dom";
const url= "https://lmsrcm.com"
const routes = [
 
  {
    path: "/session",
    name: "Home",
    icon: <HiOutlineHome />,
  },
    
      {
        path: "/responseForm",
        name: "Writing Assistant",
        icon: <HiOutlinePlus />,
       
      },
      {
        path: "/createassessment",
        name: "Create Assessment",
        icon: <HiOutlinePlus />,
       
      },
    
  
 
  {
    path: "/support",
    name: "Support",
    icon: <BiSupport />,
  },
  {
    path: "/profile",
    name: "Your Profile",
    icon: <BiUser />,
  },
  {
    path: "/demo",
    name: "Demo Video",
    icon: <HiOutlineVideoCamera />,
  },
 
 
];

function App({ children }) {
  console.log(children);
  const [visible, setVisible] = useState(true);
  const [childNav, setChildnav] = useState(false);
  const toggle = () => {
    setVisible(!visible);
  };

  const showAnimation = {
    hidden: {
      width: 0,
      opacity: 0,
      transition: {
        duration: 0.3,
      },
      show: {
        width: "auto",
        opacity: 1,
        transition: {
          duration: 0.2,
        },
      },
    },
  };
  if (!localStorage.getItem("email")) {
    // eslint-disable-next-line no-restricted-globals
    return <Signup/>
  }

  const handlesignOut = ()=>{

    signOut(auth).then(() => {
      // Sign-out successful.
      console.log("logged out");
      localStorage.setItem("email", "");

      const emptyEmail= localStorage.getItem("email")

      if(emptyEmail === ""){
       return<Signup/>
       }
    }).catch((error) => {
      // An error happened.
      console.log("error");
    });
  }
  return (
    <div className="main-container">
      <motion.div
        animate={{ width: visible ? "200px" : "35px",transition:{
          duration:0.5,
          type:"spring",
          damping:10
        } }}
        className="sidebar"
      >
        <div className="top_section">
          {visible && (
            <motion.h1
              variants={showAnimation}
              initial="show"
              animate="show"
              exit="hidden"
              className="logo"
            >
              LMSRCM
            </motion.h1>
          )}

          <div className="bars">
            <HiBars2 onClick={toggle} />
          </div>
        </div>
        <section className="routes">
          {routes.map((route) => (
            <NavLink
            activeClassName="active"
            to={route.path} target={route.target} key={route.name} className="link">
              <div className="icon">{route.icon}</div>
              <AnimatePresence>
                {visible && (
                  <motion.div
                    variants={showAnimation}
                    // initial="hidden"
                    animate="show"
                    exit="hidden"
                    className="linkText"
                  >
                    {route.name}
                  </motion.div>
                )}
              </AnimatePresence>
            </NavLink>
          ))}
        </section>
        <div className="logoutSection">
        <div className="logoutDiv" onClick={handlesignOut}><BiLogOut/><p>Logout</p></div>
        </div>
      </motion.div>
      <main className="mainOutlet">
        <Outlet />{" "}
      </main>
    </div>
  );
}

export default App;
