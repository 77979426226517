import React from 'react'
import ReactPlayer from 'react-player'


const DemoVideo = () => {
  return (
    <div>DemoVideo
    
    <ReactPlayer url='https://firebasestorage.googleapis.com/v0/b/lmsrcm-438b0.appspot.com/o/0608%20(online-video-cutter.com)%20(5).mp4?alt=media&token=38920bfc-df6d-4d94-b4d0-55bae938c8fe&_gl=1*encuyg*_ga*OTkwMDU2MTQ1LjE2ODQ5MTM4ODc.*_ga_CW55HF8NVT*MTY4NjMwOTE0Ny4zMC4xLjE2ODYzMDkyMTIuMC4wLjA.' />
    </div>
  )
}

export default DemoVideo