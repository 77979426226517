import React from "react";
import "./Signup.css";
import { useState, useEffect, } from "react";
import { useNavigate,  } from 'react-router-dom';

import { FcGoogle } from "react-icons/fc";
import {  GoogleAuthProvider, signInWithPopup,signOut } from "firebase/auth";
import { auth } from "../Firebase";
import Homepage from "./Homepage";
import App from "../SpeakerDahboard";
const Signup = () => {
    const [value,setValue] = useState('')
    const provider = new GoogleAuthProvider();
    const navigate = useNavigate();
    const handleClick = () => {
        
        signInWithPopup(auth, provider).then((data) => {
          setValue(data.user.email);
          localStorage.setItem("email", data.user.email);
    if(localStorage.getItem("email")){
     navigate('/')
    }
        });
      }

    useEffect(()=>{
        setValue(localStorage.getItem('email'))
    },[])

  return (
    <div>
    {value?<App/>:
    <div className="signupContainer">
    <div className="loginSignupHeading">
      <h1 className="LoginHeading">
        {" "}
        <button className="loginButtonOnSignupPage">Login
      </button>
      <div className="backgroundColorForButton"></div>


      </h1>
      <span>|</span>
      <h1 className="SignupHeading">
        <button className="loginButtonOnSignupPage">Sign Up
        </button>
        <div className="backgroundColorForButton"></div>

      </h1>
      <div className="loginSignupFormContainer"></div>
    </div>
    <div className="loginFormContainer">
      <div className="actualloginFormContainer">
        <div className="loginFormheading">Log in to lmsrcm.com</div>
        <div className="emailAddressSection">
          <p className="emailAddressHeading">Email Address:</p>
          <input
            type="text"
            placeholder="Enter Your email address"
            className="emailAddressInput"
          />
        </div>
        <div className="emailAddressSection">
        <p className="emailAddressHeading">Password:</p>
        <input
          type="text"
          placeholder="Enter Your password"
          className="emailAddressInput"
        />
        
      </div>
      <div className="loginButtonToActuallyLogInDiv"><button className="loginButtonToActuallyLogInButton">Login</button></div>
      <div className="or">Or</div>
      <div className="signupWithGoogleDiv"><button onClick={handleClick} className="signupWithGoogleButton"><FcGoogle/><p className="signupLine">Signup with google</p></button></div>
      <div className="forgetPasswordAndSignupSection">
      <a>Forgot Password?</a>
      <a>Don't have an account, Sign Up</a>
      </div>
      </div>
    </div>
  </div>

}
</div>
     );
};

export default Signup;
