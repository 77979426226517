import React from 'react'
import { InputTextarea } from "primereact/inputtextarea";
import { useState } from 'react';
import './Support.css'
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


const Support = () => {
    const [value, setValue] = useState('');
    const [suggestion, setSuggestion] = useState('');

    const handleCopy = () => {
    
  setValue('')
      toast.success('Your response is submitted.', { autoClose: 1000 });
    };
    const handleCopyy = () => {
    
  setSuggestion('')
      toast.success('Your response is submitted.', { autoClose: 1000 });
    };
  return (
    <div className='supportContainer'>
    <div className='supportInfo'>
    <h1 className='suppoertInfoHeading1'>Have a query?<br></br></h1>

    <div className="supportInfoQuery" style={{width:"80% "}}>
    <InputTextarea value={value} onChange={(e) => setValue(e.target.value)} rows={5} cols={30} style={{width:"100% "}} placeholder='Input your query and we will reach back to you.' />

    <div className='supportSubmitButton'>
    <button onClick={handleCopy} type="submit" className="submiButtonWA">
    Submit
  </button>
    </div>
</div>
<h1 className='suppoertInfoHeading1'>Have a Suggestion?<br></br></h1>

<div className="supportInfoQuery" style={{width:"80% "}}>
<InputTextarea value={suggestion} onChange={(e) => setSuggestion(e.target.value)} rows={5} cols={30} style={{width:"100% "}} placeholder='Input your suggestions here, we highly value your suggestions!' />
<div className='supportSubmitButton'>
<button onClick={handleCopyy}  type="submit" className="submiButtonWA" >
Submit
</button>
</div>
</div>
   </div>
   <ToastContainer/>
    </div>
    
  )
}

export default Support