import React, { useState, useEffect } from "react";
import axios from "axios";
import { InputText } from "primereact/inputtext";
import "./ContentCreateor.css";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { FiCopy } from "react-icons/fi";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Please uncomment the import down below. Thanks:

// ORIGINAL
import { db } from "../Firebase";

// FOR DEV
//import { db } from "./Firebase";

import {
  getFirestore,
  collection,
  addDoc,
  doc,
  setDoc,
  getDoc,
  onSnapshot,
  getDocs,
  arrayUnion
} from "firebase/firestore";
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { HiOutlineHome, HiOutlinePlus } from "react-icons/hi";

const ResponseForm = () => {
  const [inputText, setInputText] = useState(null);
  const [output, setOutput] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [chats, setChats] = useState([]);
  const [selectedChat, setSelectedChat] = useState(null);
  const [previousChats, setPreviousChats] = useState([]);
  const [currentTitle, setCurrentTitle] = useState(null);
  const [email, setEmail] = useState(null);

  useEffect( () => {
    const storedEmail = localStorage.getItem("email");
    if (storedEmail) {
      setEmail(storedEmail);
    }

  }, []);

    useEffect(() => {
        loadChatFromFirebase()
    }, [email])

  useEffect(() => {
    if (!currentTitle && inputText && output) {
      setCurrentTitle(inputText);
    }
    if (currentTitle && inputText && output) {
      setPreviousChats((prevChats) => [
        ...prevChats,
        {
          title: currentTitle,
          role: "user",
          content: inputText
        },
        {
          title: currentTitle,
          role: "AI",
          content: output
        }
      ]);
    }
  }, [output, currentTitle]);

  const createNewChat = () => {
    setOutput(null);
    setInputText("");
    setCurrentTitle(null);
  };

  const uniqueTitles = Array.from(
    new Set(previousChats.map((previousChat) => {console.log({previousChat}); return previousChat.title}))
  );

  const currentChat = previousChats.filter(
    (previousChat) => previousChat.title === currentTitle
  );

  const handleCopy = () => {
    
  
    toast.success('Copied to Clipboard!', { autoClose: 1000 });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      setIsLoading(true);

      const response = await axios.post(
        "https://chatbot-h6ezbthada-uc.a.run.app/api/assessment",
        {
          input_text: inputText
        }
      );

      const responseData = response.data.response;
      setOutput(responseData);

      setIsLoading(false);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  };

  const handleClick = (uniqueTitle) => {
    setCurrentTitle(uniqueTitle);
    setOutput(null);
    setInputText("");
  };

  const saveChatToFirebase = async () => {
    if (email) {
      const userCollectionRef = collection(db, "speakerQuestion");
      const userEmailCollectionRef = doc(userCollectionRef, email);

        // New field
        if(uniqueTitles.length > 0) {
            console.log(uniqueTitles)
            setDoc(userEmailCollectionRef, {chatTitles: arrayUnion(...uniqueTitles)})
        }
    
      // Create subcollections for each unique title
      await Promise.all(
        uniqueTitles.map(async (title) => {
          const titleCollectionRef = collection(userEmailCollectionRef, title);
          const chatMessages = previousChats.filter((chat) => chat.title === title);
          const chatDocs = chatMessages.map((chatMessage) => ({
            role: chatMessage.role,
            content: chatMessage.content
          }));
          await Promise.all(
            chatDocs.map(async (docData, index) => {
              const docRef = doc(titleCollectionRef, `document${index}`);
              await setDoc(docRef, docData);
            })
          );
        })
      );
        console.log("DONE")
    }
  };
  
    const loadChatFromFirebase = async () => {
        if (email) {
            console.log("asdfasdF")
          const userCollectionRef = collection(db, "speakerQuestion");
          const userEmailCollectionRef = doc(userCollectionRef, email);
            const docData = await getDoc(userEmailCollectionRef)
            console.log(docData.data())
console.log(`THIS USER HAS THESE: ${            docData.data().chatTitles}`)
            const chats = docData.data().chatTitles;
            const docs = (await Promise.all(chats.map(async chatTitle => {
                  const titleCollectionRef = collection(userEmailCollectionRef, chatTitle);;
                  return {title: chatTitle, docs: await getDocs(titleCollectionRef)}
            })))
                .map(e => {
                    const title = e.title;
                    return  e.docs.docs.map(a =>  {return {title, ...a.data()}})
                })
            console.log({docs})
            setPreviousChats(docs.flat(1))
            //setPreviousChats(docs)

            if(false) {
              await setDoc(userEmailCollectionRef, {});
            
              // Create subcollections for each unique title
              await Promise.all(
                uniqueTitles.map(async (title) => {
                  const titleCollectionRef = collection(userEmailCollectionRef, title);
                  const chatMessages = previousChats.filter((chat) => chat.title === title);
                  const chatDocs = chatMessages.map((chatMessage) => ({
                    role: chatMessage.role,
                    content: chatMessage.content
                  }));
                  await Promise.all(
                    chatDocs.map(async (docData, index) => {
                      const docRef = doc(titleCollectionRef, `document${index}`);
                      await setDoc(docRef, docData);
                    })
                  );
                })
              );
            }
            console.log("LOAD")
        }
        
    }
  

  useEffect(() => {
    saveChatToFirebase();
  }, [currentChat]);

  return (
    <div className="writingAssistantContainer">
      <div className="writingAssistantContainerDiv">
        <div className="outputDiv">
          {currentChat?.map((chatMessage, index) => (
            <div className="outputIndex" key={index}>
              <div className="outputEntry">
                <div className="inputQuestion">
                  <p className="inputQuestionP">{chatMessage.role}</p>
                </div>
                <div className="outputText">
                  <p className="ouputTextP"><pre className="chatBotOutputStyle">{chatMessage.content}</pre></p>
                  <div className="copyDivButton">
                  <CopyToClipboard text={chatMessage.content}>
                    <button
                      className="copyButton"
                      onClick={() => handleCopy()}
                    >
                      <FiCopy className="icon" />
                    </button>
                    </CopyToClipboard>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
        <form onSubmit={handleSubmit} className="inputFormWrittingAssistant">
          <div className="inputContainer">
            <InputText
              value={inputText}
              onChange={(e) => setInputText(e.target.value)}
              className="inputTextArea"
            />
            {isLoading && (
              <div className="loadingDotsContainer">
                <div className="loadingDot"></div>
                <div className="loadingDot"></div>
                <div className="loadingDot"></div>
              </div>
            )}
          </div>
          <button type="submit" className="submiButtonWA">
            Submit
          </button>
        </form>
      </div>
      <div className="newChatDiv">
        <div className="newChatButtonDiv">
          <button className="newChatButton" onClick={createNewChat}>
            <HiOutlinePlus />
            <p className="newChatText">New Chat</p>
          </button>
        </div>
        <ul className="chatHistory">
          {uniqueTitles?.map((uniqueTitle, index) => (
            <li
              key={index}
              className="chatHistoryList"
              onClick={() => handleClick(uniqueTitle)}
            >
              {uniqueTitle}
            </li>
          ))}
        </ul>
      </div>
      <ToastContainer />
    </div>
  );
};

export default ResponseForm;
