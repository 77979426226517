import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { InputText } from "primereact/inputtext";
import "./CreateAssessment.css";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { FiCopy } from "react-icons/fi";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import CreateUser from "./createUsers";
// Please uncomment the import down below. Thanks:

// ORIGINAL
import { db } from "../Firebase";

// FOR DEV
//import { db } from "./Firebase";

import {
  getFirestore,
  collection,
  addDoc,
  doc,
  setDoc,
  getDoc,
  onSnapshot,
  getDocs,
  arrayUnion,
} from "firebase/firestore";
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { HiOutlineHome, HiOutlinePlus, HiOutlineMinus } from "react-icons/hi";
import { Calendar } from "primereact/calendar";
import { CiCircleRemove } from "react-icons/ci";

const CreateAssessment = () => {
  const [inputText, setInputText] = useState(null);
  const [inputTextTitle, setInputTextTitle] = useState(null);

  const [inputTextFile, setInputTextFile] = useState(null);
  const [output, setOutput] = useState(null);
  // const [loading, setLoading] = useState(false);

  const [outputData, setOutputData] = useState([]);

  const [input, setInput] = useState("");

  const [isLoading, setIsLoading] = useState(false);

  const [date, setDate] = useState(null);
  const [userInputs, setUserInputs] = useState([{ id: 1, value: "" }]);
  const [isSaved, setIsSaved] = useState(false);
  const [factCheck, setFactCheck] = useState(false);
  const [sendToUsers, setSendToUsers] = useState(false);
  const [editOutput, setEditOutput] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const fileInputRef = useRef(null);
  // const [currentChat, setCurrentChat] = useState([]);
  useEffect(() => {
    const userEmail = localStorage.getItem("user");
    // Use the userEmail as needed
  }, []);
  

  const handleCopy = () => {
    toast.success("Copied to Clipboard!", { autoClose: 1000 });
  };

  const handleUserInputChange = (e, index) => {
    const updatedInputs = [...userInputs];
    updatedInputs[index].value = e.target.value;
    setUserInputs(updatedInputs);
  };

  const addUserInput = () => {
    const newInput = { id: userInputs.length + 1, value: "" };
    setUserInputs([...userInputs, newInput]);
  };
  const removeUserInput = (index) => {
    const updatedInputs = [...userInputs];
    updatedInputs.splice(index, 1);
    setUserInputs(updatedInputs);
  };

  const handleHoverSendToUsers = () => {
    setSendToUsers(true);
  };

  const handleMouseLeaveSendToUsers = () => {
    setSendToUsers(false);
  };
  const handleHovereditOutput = () => {
    setEditOutput(true);
  };

  const handleMouseLeaveeditOutput = () => {
    setEditOutput(false);
  };
  const handleHoverfactCheck = () => {
    setFactCheck(true);
  };

  const handleMouseLeavefactCheck = () => {
    setFactCheck(false);
  };
  const handleFileChange = (event) => {
    const files = event.target.files;
    setSelectedFiles((prevSelectedFiles) => [...prevSelectedFiles, ...files]);
  };
  // const handleRemove = () => {
  //   setSelectedFile(null);
  //   fileInputRef.current.value = "";
  // };
  const handleRemove = (index) => {
    const updatedFiles = [...selectedFiles];
    updatedFiles.splice(index, 1);
    setSelectedFiles(updatedFiles);
  };

  const handleUpload = () => {
    if (selectedFiles.length > 0) {
      // Perform upload logic here
      console.log("Uploading files:", selectedFiles);
    } else {
      console.log("No files selected");
    }
  };


  const handleSaveAssessment = async () => {

    try {
      // Get user email from localStorage
      const userEmail = localStorage.getItem("email");
const assessmentTitle = inputTextTitle;
const assignedDate = date;


if (userEmail && assessmentTitle && assignedDate) {
  // Save input and output to "SavedAssessments" collection in Firebase
  const savedAssessmentsRef = collection(db, "SavedAssessments");
  const userAssessmentRef = doc(savedAssessmentsRef, userEmail);
  
  await setDoc(userAssessmentRef, {
    title: assessmentTitle,
    assignedDate: assignedDate,
    input: inputText,
    output: output,
  });
  
  setIsSaved(true);
  toast.success("Assessment saved successfully!", { autoClose: 1000 });
} else {
  console.error("User email, assessment title, or assigned date not found.");
}
    } catch (error) {
      console.error(error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      setIsLoading(true);
      const response = await axios.post(
        "https://createassessment-h6ezbthada-uc.a.run.app/assessment",
        // "http://localhost:5050/assessment",
        {
          input_text: inputText,
        }
      );
      const responseData = response.data.response;
  // Get user email from localStorage
  const userEmail = localStorage.getItem("email");

  if (userEmail) {
    // Save input and output to Firebase Firestore
    const userRef = doc(db, "CreatedAssessments", userEmail);
    const assessmentRef = collection(userRef, "Assessments");
    await addDoc(assessmentRef, { input: inputText, output: responseData });
  } else {
    console.error("User email not found in localStorage.");
  }
    setOutputData((prevOutputData) => [
      ...prevOutputData,
      { inputText, output: responseData },
    ]);
    setInputText("");
    setIsLoading(false);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  };

  const handleWebinarOutline = async () => {
    setInputText("Create a webinar outline for the above topic");
    console.log(setInputText,"hello");

    try {
      setIsLoading(true);
      const response = await axios.post(
        // "http://localhost:5050/assessment",
        "https://createassessment-h6ezbthada-uc.a.run.app/assessment",

        {
          input_text: "Create a webinar outline for the above topic",
        }
      );

      const responseData = response.data.response;
      setOutputData((prevOutputData) => [
        ...prevOutputData,
        {
          inputText: "Create a webinar outline for the above topic",
          output: responseData,
        },
      ]);
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  };
  const handleGenerateTenmore = async () => {
    setInputText("Generate 10 more new questions on the above topic");
    console.log(setInputText,"hello");

    try {
      setIsLoading(true);
      const response = await axios.post(
        // "http://localhost:5050/assessment",

        "https://chatbot-h6ezbthada-uc.a.run.app/api/assessment",

        {
          input_text: "Generate 10 more new questions on the above topic",
        }
      );

      const responseData = response.data.response;
      setOutputData((prevOutputData) => [
        ...prevOutputData,
        {
          inputText: "Generate 10 more new questions on the above topic",
          output: responseData,
        },
      ]);
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  };
  const handleKeyDown = (e) => {
    if (e.shiftKey && e.key === 'Enter') {
      e.preventDefault();
      expandTextArea();
    }
  };

  const expandTextArea = () => {
    const textarea = textareaRef.current;
    textarea.style.height = 'auto';
    textarea.style.height = `${Math.min(textarea.scrollHeight, 200)}px`;
  };

  const textareaRef = useRef(null);

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = 'auto';
      textareaRef.current.style.height = `${Math.min(textareaRef.current.scrollHeight, 200)}px`;
    }
  }, [inputText]);
  return (
    <div className="writingAssistantContainer">
      <div className="AssessmentDetailsContainer">
        <div className="AssessmentTitleDiv">
          <p className="AssessmentTitle">Enter Title:</p>
          <InputText
            value={inputTextTitle}
            onChange={(e) => setInputTextTitle(e.target.value)}
            style={{ width: "100%" }}
          />
        </div>
        <div className="AssessmentTitleDiv">
          <p className="AssessmentTitle">Assigned Date:</p>
          <div className="AssessmentDate">
            <Calendar
              value={date}
              onChange={(e) => setDate(e.value)}
              style={{ width: "100%" }}
            />
          </div>
        </div>

      
        <div className="or">or</div>
        <div className="AssessmentTitleDiv">
          <p className="AssessmentTitle">Upload PDF</p>
          <InputText
            // // value={inputTextFile}
            // // onChange={handleFileChange}
            style={{ width: "100%" }}
            // // type="file"
            ref={fileInputRef}
            value={inputTextFile}
            onChange={handleFileChange}
            // style={{ width: "41%", height: "20%" }}
            type="file"
            multiple
          />
          <div className="submiButtonAssesmentDetailsUploadFileDiv">
            <button
              onClick={handleUpload}
              className="submiButtonAssesmentDetailsUploadFile"
            >
              Upload
            </button>
          </div>
          {selectedFiles.map((file, index) => (
            <div key={index}>
              <p className="selectedFileName">
                Selected File: {file.name}
              </p>
              <div className="submiButtonAssesmentDetailsUploadFileDivForSecondNewSessionCompCeuQuiz">
                <button
                  onClick={() => handleRemove(index)}
                  className="submiButtonAssesmentDetailsRemoveFileNewSessionCompCeuQuiz"
                >
                  <CiCircleRemove style={{ fontSize: "35px" }} />
                </button>
              </div>
            </div>
          ))}
        </div>
        <div className="assessmentSubmitButtonContainer">
          <button type="submit" className="submiButtonAssesmentDetails">
            Submit
          </button>
        </div>
      </div>
      <div className="writingAssistantContainerDiv">
      <form onSubmit={handleSubmit} className="inputFormWrittingAssistant"   disabled={isSaved}>
      <div className="inputContainer">
        <InputText
          value={inputText}
          onChange={(e) => setInputText(e.target.value)}
          onKeyDown={handleKeyDown}
          className="inputTextArea"
          maxLength={8000}

          ref={textareaRef}
        />
        {isLoading && (
          <div className="loadingDotsContainer">
            <div className="loadingDot"></div>
            <div className="loadingDot"></div>
            <div className="loadingDot"></div>
          </div>
        )}
      </div>
      <button type="submit" className="submiButtonWA">
        Submit
      </button>
    </form>
        <div className="outputDiv">
          {outputData
            .slice(0)
            .reverse()
            .map((item, index) => (
              <div key={index} className="outputIndex">
                <div className="outputEntry">
                  <div className="inputQuestion">
                    <p className="inputUser">User:</p>
                    <p className="inputQuestionP">{item.inputText}</p>
                  </div>
                </div>
                <div className="outputText">
                  <p className="inputQuestionP">AI:</p>
                  <pre className="chatBotOutputStyle">{item.output}</pre>
                  <div className="buttonsBelowOutputDiv">
                  <button className="buttonsBelowOutput"   onClick={handleSaveAssessment}>Save Assessment</button>
                  <button  className="buttonsBelowOutput" onClick={handleWebinarOutline}>Create Webinar Outline</button>
                


                  </div>
                </div>
              </div>
            ))}
        </div>
       
      </div>
    
      <ToastContainer />
    </div>
  );
};

export default CreateAssessment;
