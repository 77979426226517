import React,{useRef} from 'react'
import './NewSessionComp.css'
import { InputText } from "primereact/inputtext";
import { FcPlus } from "react-icons/fc";
import { InputTextarea } from "primereact/inputtextarea";
import { useState } from 'react';
import { Link,Outlet } from 'react-router-dom';
import { Calendar } from 'primereact/calendar';
import { db } from "../Firebase";
import { collection, doc, setDoc, addDoc } from "firebase/firestore";
import { CiCircleRemove } from "react-icons/ci";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

  const NewSessionComp = () => {
  const [inputTextFile, setInputTextFile] = useState(null);

    const [sessionHeading, setSessionHeading] = useState('');
    const [description, setDescription] = useState('');
    const [bullets, setBullets] = useState('');
  const [value, setValue] = useState('');
  const [selectedFile, setSelectedFile] = useState(null);
  const fileInputRef = useRef(null);
    
    const [items, setItems] = useState([]);
    const [date, setDate] = useState(null);
    const [inputValue, setInputValue] = useState("");
    const addItem = () => {
      if (inputValue.trim() !== "") {
        setItems([...items, inputValue]);
        setInputValue("");
      }
    };
    const handleRemove = () => {
      setSelectedFile(null);
      fileInputRef.current.value = "";
    };
    const handleFileChangee = (event) => {
      setSelectedFile(event.target.files[0]);
    };


    const handleUpload = () => {
      if (selectedFile) {
        // Perform upload logic here
        console.log("Uploading file:", selectedFile);
      } else {
        console.log("No file selected");
      }
    };

    const handleSave = () => {
      setValue('')
      toast.success('Session details have been saved.', { autoClose: 2000 });
    const userEmail = localStorage.getItem("email");
    setSessionHeading('')
    setDescription('')
    setBullets('')
    setDate('')
    if (userEmail) {
      // Retrieve the user's input values
      const heading = sessionHeading;
      const dateAndTiming = date;
      const webinardescription =  description// Retrieve the description input value;
      const webinarbullets =  bullets// Retrieve the bullets input value;


      
      // Create a reference to the "SpeakerUser" collection
   

      // Create a reference to the user's subcollection using the email as the collection name
      const userSubcollectionRef = collection(db, "SpeakerUser", userEmail, "NewSession");
      const sessionDocRef = doc(userSubcollectionRef, heading);

      // Create a document with the input values in the "NewSession" subcollection
      setDoc(sessionDocRef, {
        sessionHeading: heading,
        dateAndTiming,
        description: webinardescription,
        bullets: webinarbullets
      }).then(() => {
        // Document added successfully
        console.log("Document added to Firestore");
      }).catch((error) => {
        // Error occurred while adding the document
        console.error("Error adding document to Firestore:", error);
      });
    }
    }
    return (
      <div className='newSessionCompContainer'>
      <div className='InputLinesContainer'>
      <div className='SessionHeadingInput'>
      <p className='sessionInputLine'>Session Heading:</p>
      <div className='sessionInputLine'>
      <div className=" " style={{width:"80% "}}>
      <InputText value={sessionHeading} onChange={(e) => setSessionHeading(e.target.value)} style={{width:"100% "}}/>
  </div>
  </div>
      </div>
      <div className='SessionHeadingInput'>
      <p className='sessionInputLineDate'>Date and Timing:</p>
      <div className='sessionInputLineDate'>
      <div className=" " style={{width:"80% "}}>
      <Calendar value={date} onChange={(e) => setDate(e.value)} showIcon  showButtonBar hourFormat="12"  showTime />
  </div>
  </div>
      </div>
      <div className='SessionHeadingInput'>
      <p className='sessionInputLine'>Description:</p>
      <div className='sessionInputLine'>
      <div className="" style={{width:"80% "}}>
      <InputTextarea value={description} onChange={(e) => setDescription(e.target.value)} rows={5} cols={30} style={{width:"100% "}} />
  </div>
    </div>
      </div>
    
    
      <div className='SessionHeadingInput'>
      <p className='sessionInputLine'>Session Highlights:</p>
      <div className="sessionInputLine">
      <div className="" style={{width:"80% "}}>
      <InputTextarea value={bullets} onChange={(e) => setBullets(e.target.value)} rows={5} cols={30} style={{width:"100% "}} />
  </div>
      </div>

      </div>
      <div className='SessionHeadingInputButton'>
      <div className='ceuQuizAndCeuQuizPDFcontainer'>
      <Link to='/session/newsession/ceuquiz'>
    <button className='ceuQuizButton'>Create CEU Quiz</button>
    </Link>
    <div className="AssessmentTitleDivNewSessionCompCeuQuiz">
    <p className="AssessmentTitleNewSessionCompCeuQuiz">Upload CEU PDF</p>
    <div className='ceuQuizPdfUploadButtonNewSessionComp'>
    <InputText
      value={inputTextFile}
      onChange={handleFileChangee}
      style={{ width: "41%",height:"20%" }}
      type="file"
      ref={fileInputRef}
    />
    <div className="submiButtonAssesmentDetailsUploadFileDivNewSessionCompCeuQuiz">
      <button
        onClick={handleUpload}
        className="submiButtonAssesmentDetailsUploadFileNewSessionCompCeuQuiz"
      >
        Upload
        
      </button>
      {selectedFile && (
        
         
          <div className="submiButtonAssesmentDetailsUploadFileDivForSecondNewSessionCompCeuQuiz">
            <button
              onClick={handleRemove}
              className="submiButtonAssesmentDetailsRemoveFileNewSessionCompCeuQuiz"
            >
        <CiCircleRemove style={{fontSize:"35px"}}/>
            </button>
          </div>
    
      )}
    </div>
    </div>
    {selectedFile && (
      <div>
        <p className="selectedFileName">
          Selected File: {selectedFile.name}
        </p>
       
      </div>
    )}
    </div>
    </div>
    <p style={{width:"10%"}}>|</p>
    <button className='saveNowButton' onClick={handleSave}>Save Now </button>

      </div>
      </div>
    <Outlet/>
      <ToastContainer/>
      </div>
    )
  }

export default NewSessionComp