import React,{useState} from 'react'
import Navbar from '../components/Navbar'
import './Homepage.css'
import { Link } from 'react-router-dom';
import "primeflex/primeflex.css";
import { Outlet } from 'react-router-dom';
const Homepage = () => {
    // const [openYourSessions, setfirst] = useState(second)
  return (

   <div className='homepageWidth'>
    <div className="grid gridwidth">
    <div className="col-12 md:col-6 lg:col-3">
        <div className="surface-card shadow-2 p-3 border-round" >
            <div className="flex justify-content-between mb-3">
                <div>
                    <Link to="homepageyoursessions"  className="block text-500 font-medium mb-3">Your Sessions</Link>
                    <div className="text-900 font-medium text-xl">0</div>
                </div>
                <div className="flex align-items-center justify-content-center bg-blue-100 border-round"
                     style={{width:"2.5rem",height:"2.5rem",}}>
                    <i className="pi pi-shopping-cart text-blue-500 text-xl"/>
                </div>
            </div>
            <span className="text-green-500 font-medium">0 new </span>
            <span className="text-500">since last visit</span>
        </div>
    </div>
    <div className="col-12 md:col-6 lg:col-3">
        <div className="surface-card shadow-2 p-3 border-round">
            <div className="flex justify-content-between mb-3">
                <div>
                    <Link to="livesessions" className="block text-500 font-medium mb-3">Live Sessions</Link>
                    <div className="text-900 font-medium text-xl">0</div>
                </div>
                <div className="flex align-items-center justify-content-center bg-orange-100 border-round"
                style={{width:"2.5rem",height:"2.5rem",}}>
                    <i className="pi pi-map-marker text-orange-500 text-xl"/>
                </div>
            </div>
            <span className="text-green-500 font-medium">0 new </span>
            <span className="text-500">since last visit</span>
        </div>
    </div>
    <div className="col-12 md:col-6 lg:col-3">
        <div className="surface-card shadow-2 p-3 border-round">
            <div className="flex justify-content-between mb-3">
                <div>
                    <Link to="upcomingsessions" className="block text-500 font-medium mb-3">Upcoming Sessions</Link>
                    <div className="text-900 font-medium text-xl">0</div>
                </div>
                <div className="flex align-items-center justify-content-center bg-cyan-100 border-round"
                style={{width:"2.5rem",height:"2.5rem",}}>
                    <i className="pi pi-inbox text-cyan-500 text-xl"/>
                </div>
            </div>
            <span className="text-green-500 font-medium">0  </span>
            <span className="text-500">new sessions</span>
        </div>
    </div>
    <div className="col-12 md:col-6 lg:col-3">
        <div className="surface-card shadow-2 p-3 border-round">
            <div className="flex justify-content-between mb-3">
                <div>
                    <Link to="notifications" className="block text-500 font-medium mb-3">Notifications</Link>
                    <div className="text-900 font-medium text-xl">0 </div>
                </div>
                <div className="flex align-items-center justify-content-center bg-purple-100 border-round"
                style={{width:"2.5rem",height:"2.5rem",}}>
                    <i className="pi pi-comment text-purple-500 text-xl"/>
                </div>
            </div>
            <span className="text-green-500 font-medium">0 </span>
            <span className="text-500">responded</span>
        </div>
    </div>
</div>
<div><Outlet/></div>
</div>


  
  )
}

export default Homepage