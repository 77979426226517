import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./SpeakerDahboard";
import reportWebVitals from "./reportWebVitals";
import CreateNewSession from "./pages/CreateNewSession";
import Support from "./pages/Support";
import Content from "./pages/ContentCreator";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
  Routes,
} from "react-router-dom";
import ErrorPage from "./ErrorPage";
import Docs from "./pages/Docs";
import YourSessions from "./pages/YourSessions";
import Profile from "./pages/Profile";
import Sidebar from "./components/Sidebar";
import CreateSession from "./pages/CreateSession";
import Homepage from "./pages/Homepage";
import HomepageYourSessions from "./pages/HomepageYoursSessions";
import LiveSessions from "./pages/LiveSessions";
import UpcomingSessions from "./pages/UpcomingSessions";
import Notifications from "./pages/Notifications";
import CompletedList from "./components/CompletedList";
import PendingList from "./components/PendingList";
import NewSessionComp from "./components/NewSessionComp";
import CeuQuiz from "./components/CeuQuiz";
import DemoVideo from "./pages/DemoVideo";
import CreateAssessment from "./pages/CreateAssessment";
// import Assessment from "./pages";



const url = "https://lmsrcm.com";
const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<App />} errorElement={<ErrorPage />}>
      <Route path="session" element={<CreateSession />}>
        <Route path="completedsessions" element={<CompletedList />} />
        <Route path="pendingsessions" element={<PendingList />} />
        <Route path="newsession" element={<NewSessionComp />} >
        <Route path="ceuquiz" element={<CeuQuiz />} />
        </Route>

      </Route>

      <Route path="homepage" element={<Homepage />}>
        <Route path="homepageyoursessions" element={<HomepageYourSessions />} />
        <Route path="livesessions" element={<LiveSessions />} />
        <Route path="upcomingsessions" element={<UpcomingSessions />} />
        <Route path="notifications" element={<Notifications />} />
      </Route>

      <Route path="createNewSession" element={<CreateNewSession />} />
      <Route path="yourSessions" element={<YourSessions />} />
      <Route path="support" element={<Support />} />
      <Route path="demo" element={<DemoVideo />} />

      <Route path="profile" element={<Profile />} />
      <Route path="documentation" element={<Docs />} />
      <Route path="responseForm" element={<Content />} />
      <Route path="createassessment" element={<CreateAssessment />} />
     


    </Route>
  )
);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
