import {getApp,getApps,initializeApp} from 'firebase/app'
import { getStorage } from "firebase/storage";
// import {getFirestore} from 'firebase/firestore'
import { getFirestore } from "firebase/firestore";
// import * as firestore from "firebase/firestore";

import { getAuth, GoogleAuthProvider, signInWithPopup } from "firebase/auth";
// Import the functions you need from the SDKs you need

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDrgXfIn54PDRWrzJnu-JnYmbOnuS9nmno",
  authDomain: "lmsrcm-438b0.firebaseapp.com",
  databaseURL: "https://lmsrcm-438b0-default-rtdb.firebaseio.com",
  projectId: "lmsrcm-438b0",
  storageBucket: "lmsrcm-438b0.appspot.com",
  messagingSenderId: "734324968983",
  appId: "1:734324968983:web:b4ed185312c6ce892d1652",
  measurementId: "G-LFEP2MJW9B"
};

// Initialize Firebase
const app = getApps().length ? getApp(): initializeApp(firebaseConfig)
// db
const storage = getStorage(app);
const db = getFirestore(app)
const auth = getAuth(app);
export {db,auth,storage}