import React, { useState } from 'react';
import { collection, addDoc, serverTimestamp, doc, setDoc } from 'firebase/firestore';
import { getFirestore } from 'firebase/firestore';
import './CeuQuiz.css';
import { InputTextarea } from 'primereact/inputtextarea';
import { db } from '../Firebase';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const CeuQuiz = () => {
  const [questions, setQuestions] = useState([]);
  const [quizTitle, setQuizTitle] = useState('');
  const [value, setValue] = useState('');
  const handleCopy = () => {
    
    setValue('')
        toast.success('Your response is submitted.', { autoClose: 1000 });
      };

  useState(() => {
    const initialQuestions = Array.from({ length: 10 }, (_, index) => ({
      question: '',
      answers: Array.from({ length: 5 }, () => ''),
      rational: '',
      serialNumber: index + 1,
    }));
    setQuestions(initialQuestions);
  }, []);

  const handleQuestionChange = (e, questionIndex) => {
    const newQuestions = [...questions];
    newQuestions[questionIndex].question = e.target.value;
    setQuestions(newQuestions);
  };

  const handleAnswerChange = (e, questionIndex, answerIndex) => {
    const newQuestions = [...questions];
    newQuestions[questionIndex].answers[answerIndex] = e.target.value;
    setQuestions(newQuestions);
  };

  const handleRationalChange = (e, questionIndex) => {
    const newQuestions = [...questions];
    newQuestions[questionIndex].rational = e.target.value;
    setQuestions(newQuestions);
  };

  const handleQuizSubmit = async () => {
    setValue('')
    toast.success('CEU has been submitted.', { autoClose: 1000 });
    const db = getFirestore();
    const quizCollectionRef = collection(db, 'CEUQuiz');
    const quizDocRef = doc(quizCollectionRef, quizTitle); // Use quizTitle as the document ID

    const quizData = {
      quizTitle: quizTitle,
      questions: questions.map((question) => ({
        question: question.question,
        answers: [...question.answers],
        rational: question.rational,
      })),
    };

    await setDoc(quizDocRef, quizData);

    console.log('Quiz data submitted successfully!');
  };

  const addQuestion = () => {
    setQuestions((prevQuestions) => [
      ...prevQuestions,
      { question: '', answers: Array.from({ length: 5 }, () => ''), rational: '', serialNumber: prevQuestions.length + 1 },
    ]);
  };

  const addAnswer = (questionIndex) => {
    const newQuestions = [...questions];
    newQuestions[questionIndex].answers.push('');
    setQuestions(newQuestions);
  };

  const removeQuestion = (questionIndex) => {
    setQuestions((prevQuestions) => {
      const newQuestions = prevQuestions.filter((_, index) => index !== questionIndex);
      return newQuestions.map((question, index) => ({
        ...question,
        serialNumber: index + 1,
      }));
    });
  };

  const removeAnswer = (questionIndex, answerIndex) => {
    const newQuestions = [...questions];
    newQuestions[questionIndex].answers.splice(answerIndex, 1);
    setQuestions(newQuestions);
  };

  return (
    <div className="ceuquizContainer">
      <InputTextarea
        rows={3}
        placeholder="CEU Quiz Title"
        value={quizTitle}
        onChange={(e) => setQuizTitle(e.target.value)}
        className="quizTitleInput"
        style={{ width: '80%' }}
      />
      {questions.map((question, questionIndex) => (
        <div key={question.serialNumber} className="quizQuestion">
          <div className="QuestionAnswerContainer">
            <div className="questionSerialNumber">
              <p>{question.serialNumber}.</p>
            </div>
            <div className="questionAnswerBox">
              <InputTextarea
                rows={3}
                autoResize
                placeholder="Enter question"
                value={question.question}
                onChange={(e) => handleQuestionChange(e, questionIndex)}
                className="questionInput"
                style={{ width: '80%' }}
              />
              {question.answers.map((answer, answerIndex) => (
                <div key={answerIndex} className="quizAnswer">
                  <InputTextarea
                    rows={3}
                    autoResize
                    placeholder="Enter answer"
                    value={answer}
                    onChange={(e) => handleAnswerChange(e, questionIndex, answerIndex)}
                    className="answerInput"
                    style={{ width: '40%' }}
                  />
                  {answerIndex > 0 && (
                    <button onClick={() => removeAnswer(questionIndex, answerIndex)} type="submit" className="submiButtonWARemove">
                      Remove Answer
                    </button>
                  )}
                </div>
              ))}
              <button onClick={() => addAnswer(questionIndex)} type="submit" className="submiButtonWAAddAnswer">
                Add Answer
              </button>
              <InputTextarea
                rows={3}
                autoResize
                placeholder="Enter rational"
                value={question.rational}
                onChange={(e) => handleRationalChange(e, questionIndex)}
                className="rationalInput"
                style={{ width: '80%' }}
              />
              {questions.length > 1 && (
                <button onClick={() => removeQuestion(questionIndex)} type="submit" className="submiButtonRemoveQuestion">
                  Remove Question
                </button>
              )}
            </div>
          </div>
        </div>
      ))}
      {questions.length < 20 && (
        <button onClick={addQuestion} type="submit" className="submiButtonAllQuiz">
          Add Question
        </button>
      )}
      <button onClick={handleQuizSubmit} type="submit" className="submiButtonAllQuiz">
        Submit
      </button>
      <ToastContainer/>
    </div>
  );
};

export default CeuQuiz;
