import React, { useEffect, useState } from 'react';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { doc, setDoc, getDoc } from 'firebase/firestore';
import { db } from '../Firebase';
import './Profile.css'

const Profile = () => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [certification, setCertification] = useState('');
  const [bio, setBio] = useState('');
  const [selectedImage, setSelectedImage] = useState(null);
  const [isProfileFilled, setIsProfileFilled] = useState(false);

  const handleImageUpload = (event) => {
    const file = event.target.files[0];

    if (file) {
      const reader = new FileReader();

      reader.onload = (e) => {
        setSelectedImage(e.target.result);
      };

      reader.readAsDataURL(file);
    }
  };

  const handleSubmit = () => {
    const userEmail = localStorage.getItem('email');

    if (userEmail) {
      const speakerProfileRef = doc(db, 'speakerProfile', userEmail);

      const speakerProfileData = {
        firstname: firstName,
        lastname: lastName,
        certification: certification,
        bio: bio,
        dp: selectedImage,
      };

      setDoc(speakerProfileRef, speakerProfileData)
        .then(() => {
          console.log('Document successfully written!');
          setIsProfileFilled(true);
        })
        .catch((error) => {
          console.error('Error writing document: ', error);
        });
    }
  };

  useEffect(() => {
    const userEmail = localStorage.getItem('email');

    if (userEmail) {
      const speakerProfileRef = doc(db, 'speakerProfile', userEmail);

      getDoc(speakerProfileRef)
        .then((docSnapshot) => {
          if (docSnapshot.exists()) {
            const data = docSnapshot.data();
            setFirstName(data.firstname);
            setLastName(data.lastname);
            setCertification(data.certification);
            setBio(data.bio);
            setSelectedImage(data.dp);
            setIsProfileFilled(true);
          }
        })
        .catch((error) => {
          console.error('Error fetching document: ', error);
        });
    }
  }, []);

  const handleEditProfile = () => {
    setIsProfileFilled(false);
  };

  return (
    <div className="profileContainer">
      <div className="profileNameImageConatiner">
        <div className="profileNameConatiner">
          <div className="profileFirstName">
            <p className="profileFirstNameValue">First Name:</p>
            <div className="profilesessionInputLine">
              {isProfileFilled ? (
                <p className="profileValue" style={{ width: '100%', marginTop: '10px' }}>{firstName}</p>
              ) : (
                <div className="" style={{ width: '100%', marginTop: '10px' }}>
                  <InputText
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                    style={{ width: '100%' }}
                    placeholder="Joe"
                  />
                </div>
              )}
            </div>
          </div>
          <div className="profileFirstName">
            <p className="profileFirstNameValue">Last Name:</p>
            <div className="profilesessionInputLine">
              {isProfileFilled ? (
                <p className="profileValue" style={{ width: '100%', marginTop: '10px' }}>{lastName} </p>
              ) : (
                <div className="" style={{ width: '100%', marginTop: '10px' }}>
                  <InputText
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                    style={{ width: '100%' }}
                    placeholder="Mcallen"
                  />
                </div>
              )}
            </div>
          </div>
          <div className="profileFirstName">
            <p className="profileFirstNameValue">Speaker Certifications:</p>
            <div className="profilesessionInputLine">
              {isProfileFilled ? (
                <p className="profileValue" style={{ width: '100%', marginTop: '10px' }}>{certification} </p>
              ) : (
                <div className="" style={{ width: '100%', marginTop: '10px' }}>
                  <InputText
                    value={certification}
                    onChange={(e) => setCertification(e.target.value)}
                    style={{ width: '100%' }}
                    placeholder='"AAPC", "AHIMA"'
                  />
                </div>
              )}
            </div>
          </div>
          <div className="profileFirstName">
            <p className="profileFirstNameValue">Bio:</p>
            <div className="profilesessionInputLine">
              {isProfileFilled ? (
                <p className="profileValue" style={{ width: '100%', marginTop: '10px' }}>{bio} </p>
              ) : (
                <div className="" style={{ width: '100%', marginTop: '10px' }}>
                  <InputTextarea
                    value={bio}
                    onChange={(e) => setBio(e.target.value)}
                    rows={5}
                    cols={30}
                    style={{ width: '100%' }}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="profileImageConatiner">
          <div className="imageBox" style={{ maxWidth: '250px', maxHeight: '250px' }}>
            {selectedImage && (
              <img
                src={selectedImage}
                alt="Selected"
                style={{ width: '100%', height: '100%', borderRadius: '50%' }}
              />
            )}
          </div>
          {!isProfileFilled && (
            <div className="inputFileImage">
              <input
                type="file"
                id="uploadInput"
                accept="image/*"
                onChange={handleImageUpload}
                style={{
                  position: 'absolute',
                  opacity: 0,
                  height: 0,
                  width: 0,
                  zIndex: -1,
                }}
              />
              <label htmlFor="uploadInput" className="inputImageButton">
                Upload Image
              </label>
            </div>
          )}
        </div>
      </div>
      <div className="profileSubmitDiv">
        {isProfileFilled ? (
          <button type="button" className="profileSubmit" onClick={handleEditProfile}>
            Edit Profile
          </button>
        ) : (
          <button type="button" className="profileSubmit" onClick={handleSubmit}>
            Submit
          </button>
        )}
      </div>
    </div>
  );
};

export default Profile;
